/* eslint-disable react/no-unknown-property */
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Suspense,
} from 'react';
import * as THREE from 'three';
import { Canvas, useLoader, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DDSLoader } from 'three-stdlib';

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

function Precompile() {
  const { gl, scene, camera } = useThree();
  useLayoutEffect(() => {
    gl.compile(scene, camera);
  }, []);
  return null;
}

function SpinningCube() {
  const boxRef = useRef();
  useFrame(() => {
    if (boxRef.current) {
      boxRef.current.rotation.y -= 0.05;
    }
  });
  return (
    <mesh ref={boxRef} rotation-x={Math.PI * 0.15} rotation-y={Math.PI * 0.25}>
      <boxBufferGeometry args={[0.4, 0.4, 0.4]} />
      <meshStandardMaterial color="#36796B" transparent opacity={0.4} />
    </mesh>
  );
}

function ScannedModel({ scan }) {
  const [scale, setScale] = useState(0.001);
  const [scaledModel, setScaledModel] = useState(null);

  const materials = useLoader(MTLLoader, `${scan.path}/scan.mtl`);
  const model = useLoader(OBJLoader, `${scan.path}/scan.obj`, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  useEffect(() => {
    if (model) {
      console.log('Model loaded: ', model);
      const { geometry } = model.children[0];
      geometry.computeBoundingSphere();
      const scalefactor =
        2.0 * (1.0 / model.children[0].geometry.boundingSphere.radius);
      model.userData = { scalefactor };
      // scene.add(new THREE.BoxHelper(model, 0x0000ff))
      setTimeout(() => {
        console.log('setting model');
        setScale(scalefactor);
        setScaledModel(model);
      }, 1000);
    }
  }, [model]);
  return (
    <>
      {!scaledModel && <SpinningCube />}
      {scaledModel && <primitive object={scaledModel} scale={scale} />}
    </>
  );
}

function Dolly({ light }) {
  // This one makes the light move along the camera
  useFrame(({ camera }) => {
    light.position.copy(camera.position);
    // camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30
  });
  return null;
}

// status: searching, not found, loading, display

function Viewer({ scan }) {
  const camera = { fov: 45 };
  const [light, set] = useState();
  const viewChanged = () => {
    // console.log('view changed! ');
  };

  return (
    <>
      {scan && (
        <Canvas className="bg-[#36796B]/10" camera={camera}>
          <ambientLight color={0x888888} intensity={1.0} />
          {/* <axesHelper args={[2, 2, 2]} /> */}
          <directionalLight ref={set} color={0x888888} intensity={1.0} />
          {false && light && <directionalLightHelper args={[light, 8]} />}
          <Suspense fallback={null}>
            <Precompile />
            <ScannedModel scan={scan} light={light} />
            <OrbitControls onUpdate={viewChanged} />
            {light && <Dolly light={light} />}
          </Suspense>
        </Canvas>
      )}
      {!scan && (
        <div className="bg-[#36796B]/10 h-full p-12">
          <p>Unfortunately, this scan is not available!</p>
        </div>
      )}
    </>
  );
}

export default Viewer;
