import './App.css';
import { React } from 'react';
import ScanView from './views/ScanView';

function App() {
  return (
    <div className="App">
      <ScanView />
    </div>
  );
}

export default App;
